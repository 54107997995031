@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=fre5sus&ht=tk&f=39262&a=536001&app=typekit&e=css");
@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

.myaccount__menu {
  display: none;
}

.wh-wrdauth--isloggedin .myaccount__menu {
  display: block;
}
.wh-wrdauth--isloggedin aside.widget-login {
  display: none;
}
.wh-wrdauth--isloggedin .hide-loggedin {
  display: none;
}

.webshop-account__customerfieldset, .webshop-account__recentorders, .webshop-account__meta, .webshop-account__address, .webshop-account__ordercontents {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
}
.webshop-account__customerfieldlabel {
  font-weight: 500;
  margin-right: 5px;
}
.webshop-account__customerfieldlabel:after {
  content: ":";
}
.webshop-account__changelink, .webshop-account__productreviewlink {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 0;
  font-weight: 300;
  white-space: normal;
  background-color: #8fc13e;
  border-color: #8fc13e;
  color: #fff !important;
  border-color: #8fc13e;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.webshop-account__changelink:hover, .webshop-account__changelinkfocus, .webshop-account__productreviewlink:hover, .webshop-account__productreviewlinkfocus {
  background-color: #a2db46;
  border-color: #a2db46;
  text-decoration: none;
  color: #fff;
}
.webshop-account__links {
  list-style: none;
}