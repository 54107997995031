html.webshop--ishomepage .main--margintop {
  margin-top: 0;
  width: 100%;
}
html.webshop--ishomepage .usps__ecoyogi {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
}
html.webshop--ishomepage #product_listing {
  padding: 30px 15px 15px 15px;
  margin-left: 0;
  margin-right: 0;
  max-width: 1900px;
  margin: 0 auto;
}
html.webshop--ishomepage main .threecol {
  padding: 35px 15px 35px 15px;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 576px) {
  html.webshop--ishomepage #product_listing {
    margin: 0;
  }
  html.webshop--ishomepage #product_listing .col-lg-4 {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) and (min-width: 320px) {
  html.webshop--ishomepage main {
    margin-top: 0;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 1200px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  html.webshop--ishomepage main .threecol {
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1400px) {
  html.webshop--ishomepage #product_listing .col-lg-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

html.webshop--ishomepage main > * {
  max-width: 100%;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}