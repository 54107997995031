@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=fre5sus&ht=tk&f=39262&a=536001&app=typekit&e=css");
@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

webshop-product.webshop--isoutofstock .product__alwaysbeordered {
  display: none;
}

@media (min-width: 320px) and (max-width: 576px) {
  #productpage_container {
    margin-bottom: 100px !important;
  }
}
#productpage_container .badge-danger {
  background-color: #d35f5f;
  padding: 6px 19px 4px 19px;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
#productpage_container .product__discountslogan {
  color: #8fc13e;
  font-weight: 500;
  display: block;
  border: 1px solid #8fc13e;
  margin-bottom: 10px;
  padding: 10px;
  float: right;
  width: fit-content;
  clear: both;
  margin-top: 10px;
}
#productpage_container .product__discountslogan:empty {
  display: none;
}

:target:before {
  display: block;
  content: " ";
  height: 140px; /* Give height of your fixed element */
  margin-top: -140px; /* Give negative margin of your fixed element */
  visibility: hidden;
}

html.webshop--isproductpage {
  /*
    Hide <select> once the replacement .dd-container is live
  */
  /* Large desktop */
  /* Desktop */
}
html.webshop--isproductpage .dd-container + select {
  display: none;
}
html.webshop--isproductpage .dd-selected {
  font-weight: normal;
  position: relative;
}
html.webshop--isproductpage .dd-container {
  position: relative;
  display: inline-block;
  top: -5px;
}
html.webshop--isproductpage .dd-selected-text,
html.webshop--isproductpage .webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
}
@media (min-width: 1300px) {
  html.webshop--isproductpage #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  html.webshop--isproductpage #image-preview img {
    max-width: 400px;
  }
}
@media (min-width: 979px) and (max-width: 1300px) {
  html.webshop--isproductpage #image-preview {
    position: absolute;
    z-index: 2001;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.4);
  }
  html.webshop--isproductpage #image-preview img {
    max-width: 400px;
  }
}
@media (max-width: 992px) {
  html.webshop--isproductpage .webshop--isproductpage .product__options {
    border: none;
    padding: 0;
    box-shadow: none !important;
  }
  html.webshop--isproductpage .webshop--isproductpage .product__options .dd-container {
    margin-bottom: 0;
  }
  html.webshop--isproductpage .webshop--isproductpage .product__options .dd-select {
    border-radius: 2px;
    border: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
}
@media (min-width: 480px) and (max-width: 979px) {
  html.webshop--isproductpage .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
html.webshop--isproductpage .option__kleur .webshop-product__optionlabel,
html.webshop--isproductpage .option__kies-je-kleur .webshop-product__optionlabel {
  font-weight: 400;
  font-size: 19px;
  font-family: "ff-scala-sans-pro", sans-serif;
}
html.webshop--isproductpage .option__kleur .dd-selected .dd-selected-image,
html.webshop--isproductpage .option__kies-je-kleur .dd-selected .dd-selected-image {
  display: none;
}
@media (max-width: 480px) {
  html.webshop--isproductpage .option__kleur .dd-selected,
  html.webshop--isproductpage .option__kies-je-kleur .dd-selected {
    padding: 4px 5px;
  }
}
html.webshop--isproductpage .option__kleur .dd-select,
html.webshop--isproductpage .option__kies-je-kleur .dd-select {
  display: none;
}
html.webshop--isproductpage .option__kleur .dd-options,
html.webshop--isproductpage .option__kies-je-kleur .dd-options {
  display: block !important;
  background-color: transparent;
  box-shadow: none;
  border: none;
  position: inherit;
}
html.webshop--isproductpage .option__kleur .dd-options li,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li {
  display: inline-block;
  float: left;
  min-width: 60px;
  margin-right: 10px;
  text-align: center;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option {
  display: block;
  padding: 0;
  margin: 0 10px 0 0;
  border-radius: 15rem;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  overflow: visible;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.aqua-blauw, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.aquablauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.aqua-blauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.aquablauw {
  background-color: #00FFFF;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.blauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.blauw {
  background-color: #0000FF;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.donkerblauw, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.donker-blauw, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.midnight,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.donkerblauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.donker-blauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.midnight {
  background-color: #00008B;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.marmer-blauw, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.marmerblauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.marmer-blauw,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.marmerblauw {
  background-color: #566D7E;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.zwart,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.zwart {
  background-color: #000;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.licht-paars, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.lichtpaars,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.licht-paars,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.lichtpaars {
  background-color: #e9e3f4;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.plum, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.aubergine,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.plum,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.aubergine {
  background-color: #483248;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.paars,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.paars {
  background-color: #800080;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.lavendel,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.lavendel {
  background-color: #b57edc;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.rood, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.bordeaux, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.ruby-red,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.rood,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.bordeaux,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.ruby-red {
  background-color: #FF0000;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.groen, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.donkergroen, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.donker-groen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.groen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.donkergroen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.donker-groen {
  background-color: #98b13a;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.olijf-groen, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.olijfgroen, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.licht-groen, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.lichtgroen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.olijf-groen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.olijfgroen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.licht-groen,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.lichtgroen {
  background-color: #BDECB6;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.oranje,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.oranje {
  background-color: #ffa500;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.kurk, html.webshop--isproductpage .option__kleur .dd-options li .dd-option.bruin,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.kurk,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.bruin {
  background-color: #CD7F32;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.sand,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.sand {
  background-color: #c2b280;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.hennep,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.hennep {
  background-color: #BAAEA0;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.beige,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.beige {
  background-color: #fff4c2;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.grijs,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.grijs {
  background-color: #808080;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.antraciet,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.antraciet {
  background-color: #383e42;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.roze,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.roze {
  background-color: #FFC0CB;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.gekleurd,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.gekleurd {
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option.dd-option-selected:before,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option.dd-option-selected:before {
  content: "\f00c";
  font-family: fontAwesome;
  color: #fff;
  font-size: 14px;
}
html.webshop--isproductpage .option__kleur .dd-options li .dd-option-text,
html.webshop--isproductpage .option__kies-je-kleur .dd-options li .dd-option-text {
  font-size: 14px;
}

.product__options {
  margin-bottom: 70px;
}
@media (min-width: 320px) and (max-width: 991px) {
  .product__options {
    margin-bottom: 30px;
  }
}
.product__options .card {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.product__options .card .card-body {
  padding: 20px;
}
.product__options .card .card-body .col-sm-4 {
  max-width: 161px;
}
.product__options .card .card-body .product__instocktext {
  font-size: 16px;
  font-weight: 500;
}
.product__options .card .card-body .product__instocktext i {
  color: #8fc13e;
}
.product__options .card .card-body .text-danger a {
  color: #dc3545;
  text-decoration: underline;
}
.product__options .card .product__quantitydiscounts {
  padding: 15px;
}
.product__options .card .product__quantitydiscounts .quantitydiscounts_percentage {
  color: #8fc13e;
  font-weight: 500;
  font-size: 14px;
}
.product__options .card #amount_container {
  max-width: 146px;
}
.product__options .card .in-decrease_amount {
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}
.product__options .card .in-decrease_amount .value_button {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0px;
  width: 45px;
  height: 49px;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
  background: #faf8f5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product__options .card .in-decrease_amount .value_button:hover {
  cursor: pointer;
}
.product__options .card .in-decrease_amount #decrease {
  border-right: none;
}
.product__options .card .in-decrease_amount #increase {
  border-left: none;
}
.product__options .card .in-decrease_amount input#amount {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 45px;
  height: 49px;
}
.product__options .card .in-decrease_amount input#amount::-webkit-inner-spin-button, .product__options .card .in-decrease_amount input#amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product__options .card .card-footer {
  background-color: #faf8f5;
}
.product__options .card .card-footer ul.checkmarks {
  margin: 0 0 0px 0;
  padding: 0;
}
.product__options .card .card-footer ul.checkmarks li {
  padding: 0 0 0 25px;
}
.product__options .card .card-footer ul.checkmarks li:before {
  content: "\f058";
  left: 0;
}
.product__options .card .card-footer .product_environment_label img {
  width: 20px;
  margin-right: 2px;
}
.product__options .card .card-footer .product_environment_label .fa {
  opacity: 0.7;
  position: relative;
  top: -2px;
}
.product__options h1 {
  font-size: 28px;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__optiontitle {
  float: left;
  width: 100px;
}
.product__options .webshop-product__optiontitle {
  font-weight: 400;
  margin-bottom: 0;
}
.product__options .webshop-product__priceblock {
  margin-top: 10px;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #d35f5f;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d35f5f;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 400;
  color: #000;
}
.product__options .discountslogan {
  color: #8fc13e;
  font-weight: 500;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__info {
  margin-top: -110px;
  margin-bottom: 40px;
}
.product__info .nav-link {
  color: #000;
  font-weight: 400;
}
.product__info h2 {
  font-size: 32px;
  font-weight: 400;
}
.product__info .nav-pills .nav-link {
  padding: 1rem 0.7rem;
}
.product__info .nav-pills .nav-item.active {
  border-bottom: 4px solid #c7c1bd;
}
@media (min-width: 320px) and (max-width: 576px) {
  .product__info {
    margin-top: -90px;
  }
  .product__info .nav-pills .nav-link {
    padding: 0.7rem 1rem;
    border: 1px solid #c7c1bd;
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 300;
    border-radius: 0;
  }
  .product__info .nav-pills .nav-item.active {
    border-bottom: 0;
  }
  .product__info .nav-pills .nav-item.active .nav-link {
    background-color: #fff;
  }
}

#product__images__preview__container {
  clear: both;
}

.product__images__grid {
  margin-top: 10px;
  max-height: 100px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .col-2 .slick-track,
  .col-2 .slick-list {
    height: 400px !important;
  }
}

.slick-track {
  margin-left: 0;
  margin-right: auto;
}

.slick-vertical .slick-slide {
  border: 1px solid #c7c1bd;
  margin-bottom: 10px;
  width: 100% !important;
  opacity: 0.5;
}
.slick-vertical .slick-slide.slick-current {
  opacity: 1;
}

@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
  #product__images__grid__container {
    padding-left: 5px;
  }
  .product__images__preview img {
    max-height: 250px;
  }
}
.mfp-figure:after {
  background-color: #fff;
}

.slick-next {
  right: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
  margin-top: 20px;
}

.slick-prev {
  left: auto;
  z-index: 1;
  position: inherit;
  margin: 0 auto;
}

.slick-prev:before {
  font-family: fontAwesome;
  content: "\f0aa";
  color: #50505c;
}

.slick-next:before {
  font-family: fontAwesome;
  content: "\f0ab";
  color: #50505c;
}

.product__image img {
  max-height: 100px;
  width: auto;
}

.product_social_sharing {
  text-align: right;
  font-size: 24px;
  color: #50505c;
}
.product_social_sharing label {
  cursor: pointer;
}
.product_social_sharing i {
  margin-right: 20px;
  margin-bottom: 10px;
  color: #50505c;
}

.tab-container {
  overflow: hidden;
}
.tab-container .tab-section {
  margin-bottom: 50px;
}
.tab-container .tab-section .table-sm td,
.tab-container .tab-section .table-sm th {
  padding: 0.4rem;
}
.tab-container .tab-section .table td,
.tab-container .tab-section .table th {
  border-top: 0;
}
.tab-container .tab-section .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfbf9;
}

.product__tags {
  margin-top: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.product__tag {
  background: transparent;
  padding: 8px 12px;
  display: block;
  float: left;
  margin: 0 10px 10px 0;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 300;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.product__related {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .product__related .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 480px) and (max-width: 979px) {
  #productpage_container {
    padding-bottom: 40px;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 768px) {
  .product__options {
    position: -webkit-sticky;
    position: sticky;
    top: 125px;
  }
  .webshop-product__priceblock__column {
    min-width: 145px;
  }
}
@media (min-width: 320px) and (max-width: 979px) {
  .productitem .btn {
    visibility: visible;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .product__options .card .in-decrease_amount {
    margin-right: 0;
  }
  .webshop-product__add {
    font-size: 15px;
    padding: 10px 12px;
    margin-left: 10px;
  }
}
input.ecoyogi__favorite {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}
input.ecoyogi__favorite + label {
  margin: 0;
}
input.ecoyogi__favorite:checked + label > .ecoyogi--hide-if-favorite, input.ecoyogi__favorite:not(:checked) + label > .ecoyogi--show-if-favorite {
  display: none;
}