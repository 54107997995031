@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=fre5sus&ht=tk&f=39262&a=536001&app=typekit&e=css");
@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

header {
  background-color: #fff;
  transition: 0.2s;
}
header .topbar {
  background-color: #f4f4f4;
  color: #50505c;
}
header .topbar .topnav {
  margin-bottom: 0;
}
header .topbar .topnav .dropdown:hover > .dropdown-menu,
header .topbar .topnav .dropdown:focus > .dropdown-menu {
  display: block;
}
header .topbar .topnav a {
  padding: 0 0.5rem;
  color: #50505c;
}
header .topbar .rating-stars {
  top: 5px;
}
header .stickynav {
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}
header .stickynav .topleftpanel .logo {
  margin: 10px 0;
}
header .stickynav .topleftpanel .logo img {
  width: 260px;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel .logo {
  display: inline-block;
  margin: 15px 0;
  transition: 0.2s;
}
header .topleftpanel .logo > img {
  height: auto;
  width: 260px;
}
header .toprightpanel #topsearchform {
  position: absolute;
  right: 0;
  z-index: 1;
  -webkit-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  max-width: 250px;
  overflow: hidden;
}
header .toprightpanel #topsearchform * {
  outline: none;
  box-sizing: border-box;
}
header .toprightpanel #topsearchform .search__wrapper {
  position: relative;
}
header .toprightpanel #topsearchform .search__wrapper .search__field {
  width: 31px;
  height: 35px;
  color: transparent;
  padding: 0.35em 30px 0.35em 0;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
header .toprightpanel #topsearchform .search__wrapper .search__field:focus {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  width: 30vw;
  padding-left: 5px;
  color: #000;
  cursor: default;
}
header .toprightpanel #topsearchform .search__wrapper .search__field:focus ~ .search__icon {
  background-color: #fff;
  cursor: pointer;
  pointer-events: auto;
}
header .toprightpanel #topsearchform .search__wrapper .search__field::-webkit-input-placeholder, header .toprightpanel #topsearchform .search__wrapper .search__field:-moz-placeholder, header .toprightpanel #topsearchform .search__wrapper .search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  opacity: 1;
}
header .toprightpanel #topsearchform .search__wrapper .search__field::-webkit-input-placeholder[style*=hidden], header .toprightpanel #topsearchform .search__wrapper .search__field:-moz-placeholder[style*=hidden], header .toprightpanel #topsearchform .search__wrapper .search__field:-ms-input-placeholder[style*=hidden] {
  color: #000;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
header .toprightpanel #topsearchform .search__wrapper .search__icon {
  position: absolute;
  background-color: transparent;
  top: 0;
  color: #000;
  right: 0;
  width: 36px;
  height: 30px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
}
header .toprightpanel i {
  color: #375b7b;
}
header .toprightpanel a {
  color: #375b7b;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
header .toprightpanel .badge {
  background: #acd35f;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: -12px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px 4px 4px 4px;
  min-width: 20px;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 0 10px;
}
header .toprightpanel .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (min-width: 992px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
}
@media (max-width: 767px) {
  header .logo {
    margin: 10px 0;
  }
  header .logo > img {
    max-width: 230px;
  }
  header .stickynav {
    position: fixed;
    top: 0;
    z-index: 3;
  }
  header .stickynav .logo {
    margin: 0;
  }
  header .stickynav .logo img {
    max-width: 200px;
  }
}
@media (max-width: 565px) {
  header .container {
    max-width: 100%;
  }
  header .review_top {
    margin: 0 auto;
  }
  header .logo > img {
    max-width: 175px;
  }
  header.stickynav .logo img {
    max-width: 200px;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel {
    text-align: center;
  }
  header #mainbar .col-auto {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar {
  padding: 0;
  margin-top: 10px;
}

.navbar-brand {
  font-size: 1rem;
}

.navbar-toggler {
  border: none;
}

#mainnav {
  width: 100%;
  justify-content: center;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
#mainnav .toplevelmenu__container .nav-link {
  display: block;
  padding: 0.6rem;
  text-decoration: none;
  white-space: nowrap;
  color: #375b7b;
  font-weight: 500;
  font-size: 17px;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #50505c;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
}
#mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
  display: block;
}
#mainnav .toplevelmenu__container li {
  display: inline-block;
}
#mainnav .toplevelmenu__container li.bg-primary {
  position: relative;
  top: -2px;
  height: 43px;
}
#mainnav .toplevelmenu__container li:hover li a:hover {
  color: #375b7b;
  text-decoration: underline;
}
#mainnav .dropdown-menu {
  width: max-content;
  padding: 2rem;
  margin: 0;
  background-color: #fff;
  border: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
#mainnav .dropdown-menu p.normal {
  display: none;
}
#mainnav .dropdown-menu ul {
  display: block;
  float: left;
  padding-left: 0;
  margin-right: 20px;
  margin-bottom: 0;
}
#mainnav .dropdown-menu ul li {
  display: block;
  color: #375b7b;
  position: relative;
  margin-left: 0;
  padding: 5px 10px;
}
#mainnav .dropdown-menu ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #375b7b;
}
#mainnav .dropdown-menu ul li strong, #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}