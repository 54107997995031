@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=fre5sus&ht=tk&f=39262&a=536001&app=typekit&e=css");
@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

.webshop--iscategorypage #sidebar,
.webshop--istagpage #sidebar {
  margin-top: 0;
}
.webshop--iscategorypage .categoryintro .btn-link.collapsed:after,
.webshop--istagpage .categoryintro .btn-link.collapsed:after {
  content: "+ Lees meer";
}
.webshop--iscategorypage .categoryintro .btn-link:not(.collapsed):after,
.webshop--istagpage .categoryintro .btn-link:not(.collapsed):after {
  content: "- Lees minder";
}
.webshop--iscategorypage .categoryintro #categoryintro_collapse.collapse:not(.show),
.webshop--istagpage .categoryintro #categoryintro_collapse.collapse:not(.show) {
  display: block;
}
@media (max-width: 767px) {
  .webshop--iscategorypage .categoryintro #categoryintro_collapse.collapse:not(.show),
  .webshop--istagpage .categoryintro #categoryintro_collapse.collapse:not(.show) {
    display: block;
    height: 135px;
    overflow: hidden;
  }
  .webshop--iscategorypage .categoryintro #categoryintro_collapse.collapsing,
  .webshop--istagpage .categoryintro #categoryintro_collapse.collapsing {
    height: 3rem;
  }
}

html.sidebarjs--open {
  overflow-y: hidden;
}

#product_listing {
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 565px) {
  #product_listing {
    margin-bottom: 50px;
  }
}
#product_listing .productsgrid__product--hidden {
  display: none;
}

#productsgrid_anchor {
  position: absolute;
  top: -150px;
  left: 0;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #58595b;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

.categorygrid:empty {
  display: none;
}
@media (max-width: 565px) {
  .categorygrid {
    margin-bottom: 50px;
    padding: 15px 0;
    margin-right: -15px;
  }
}

.webshop-products__item[data-webshop-in-stock="0"] .productitem .product__stockinfo .in_stock {
  display: none;
}
.webshop-products__item[data-webshop-in-stock="0"] .productitem .product__stockinfo .out_of_stock {
  display: block;
  color: #d35f5f;
}

.productitem .product__stockinfo {
  padding: 9px 1rem 0px 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #faf8f5;
}
.productitem .product__stockinfo .in_stock {
  color: #8fc13e;
}
.productitem .product__stockinfo .out_of_stock {
  display: none;
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
}
.productitem .badge-danger {
  background-color: #d35f5f;
  position: absolute;
  right: 0;
  padding: 6px 19px 4px 19px;
  border-radius: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.productitem:hover, .productitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.productitem:hover .btn, .productitem:focus .btn {
  visibility: visible;
  background-color: #8fc13e;
  border-color: #8fc13e;
  color: #fff;
}
.productitem.ribbon:before {
  width: 124px;
  height: 124px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 200px;
}
.productitem .productitem__image img {
  max-height: 200px;
  width: auto;
}
.productitem .card-body {
  padding: 1rem;
}
.productitem .card-body .productitem__rating {
  display: flex;
}
.productitem .card-body .productitem__rating .rating-stars {
  background-image: url(../../web/img/stars-review-grey-sm.svg);
  height: 17px;
  width: 90px;
}
.productitem .card-body .productitem__rating .rating-stars > .inner {
  background-image: url(../../web/img/stars-review-gold-sm.svg);
  background-size: 90px;
}
.productitem .card-body ul.checkmarks li {
  padding: 0px 0 0 25px;
}
.productitem .card-body ul.checkmarks li:before {
  left: 0;
}
.productitem .card-body .productitem__description {
  font-size: 15px;
  line-height: 21px;
}
.productitem .card-body .productitem__description .product_environment_label {
  padding: 7px 0;
  margin: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}
.productitem .card-body .productitem__description .product_environment_label img {
  margin-right: 5px;
}
.productitem .card-body .productitem__description p {
  margin-bottom: 0;
}
.productitem .card-footer {
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  border-top: 0;
}
.productitem__title {
  font-size: 18px;
  padding-bottom: 5px;
  min-height: 50px;
}
.productitem__discountslogan {
  color: #fff;
  font-size: 16px;
  background-color: #8fc13e;
  padding: 2px 10px;
  margin-bottom: 5px;
  font-weight: 500;
  text-align: center;
}
.productitem .productitem__price {
  position: absolute;
  left: 0;
  bottom: 0;
}
.productitem__baseprice {
  line-height: 14px;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  position: relative;
  color: #d35f5f;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d35f5f;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 22px;
  font-weight: 400;
  color: #50505c;
}
@media (max-width: 565px) {
  .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}
.productitem .btn {
  min-width: 125px;
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  padding: 15px;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
  min-height: 200px;
}
.categoryitem .card-body {
  padding: 1rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: transparent;
}
.categoryitem__title {
  font-size: 24px;
  padding-bottom: 5px;
}
.categoryitem .btn {
  font-size: 16px;
}

@media (min-width: 320px) and (max-width: 576px) {
  html.page--categories main {
    margin-top: 30px;
  }
}