.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}
aside
{
  border-bottom:1px solid #e4e2e1;
  padding-bottom: 20px;
}
.widget-image
{
  margin-bottom: 20px;
}
/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter
{
  padding-bottom: 0;
}

#filterSideNav .widget-productfilter .aside__title
{
  color:#fff;
}
#filterSideNav .productfilter__activelabel
{
  background-color: #756356;
  color:#fff;
}
.productfilter__activelabel
{
  background-color: #fff;
  padding: 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 3px;
}
.productfilter__activeremove:before
{
  content: "x";
  float: right;
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color:#d35f5f;
}
.productfilter[data-type="checkbox"],
.productfilter[data-type="select"]
{
  max-height: 205px;
  overflow:hidden;
}
.productfilter + .productfilter
{
  border-top: 1px solid #e4e2e1;
}
.productfilter:last-child
{
  padding-bottom: 0;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#fff;
}
#filterSideNav ul li
{
  border-bottom:0!important;
}
#filterSideNav .productfilter--select
{
  border:none;
}
.productfilter-title
{
  padding:0;
  margin: 10px 0;
  display: block;
  color:#50505c;
  font-weight: 500;
}
.productfilter-title[data-toggle="collapse"]:after
{
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter-title[data-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.productfilter--optionlist li
{
  display: flex;
  align-content: flex-start;
}
.productfilter--optionlist li input
{
  margin-top: 6px;
}
.productfilter--optionlist li input[type="checkbox"] {
    margin-top: -2px;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom:0;
}
.productfilter--select
{
  width: 100%;
  padding: 5px;
  border:none;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}

/**/
.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}

/**/
.widget-inlinereview__comment
{
  background-color: #eee;
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment:after
{
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 30px;
  border-left: 10px solid transparent;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
  .widget-inlinereview__comment > *:before
, .widget-inlinereview__comment > *:after
{
  display: inline-block;
  content: '"';
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
}

/**/
.widget-fullwidthimage a
{
  display: block;
  text-decoration: none;
}
.widget-fullwidthimage img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.category__banner
{
  background-color: #5e5045;
}
.category__banner h2,
.category__banner p
{
  color:#fff;
}
/**/
.popular-categorieslist
{
  background-color: #fff;
  padding:20px;
  -webkit-box-shadow: 0 2px 30px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 30px 0 rgba(0,0,0,.12);
  position: relative;
}
.popular-categorieslist h2
{
  font-size: 32px;
}
.popular-categorieslist ul
{
  margin: 0-20px;
}
.popular-categorieslist ul li
{
  padding: 15px 0;
  border-bottom: 2px solid #e7e2dc;
}
.popular-categorieslist ul li a
{
  font-size:19px;
  color:#50505c;
  padding:0 20px;
  display: block;
}
.popular-categorieslist ul li a:after
{
  content: '\f105';
  position: absolute;
  right: 20px;
  font-size: 19px;
  color:#7aaf61;
  font-family: fontAwesome;
}
.popular-categorieslist .btn
{
  position: absolute;
  bottom:-20px;
  right:20px;
}

/**/
.widget-categorieslist
{
  list-style-type: none;
  margin: 10px 0 20px;
  padding: 0;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

.usps__ecoyogi
{
  padding:10px 0 8px 0;
  background-color: rgba(255,255,255,0.5);
  -webkit-box-shadow: 0 2px 30px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 30px 0 rgba(0,0,0,.12);
  font-size: 17px;
  min-height: 51px;
}
.usps__ecoyogi .col-sm-12 div
{
  align-self: center;
}
.usps__ecoyogi .icon_wrapper
{
  margin-right: 10px;
  display: inline-block;
}
.usp_cycle div {
    display: none;
}
@media (min-width: 320px) and (max-width: 480px)
{
  .usp_cycle div
  {
    font-size: 13px;
  }
}

/* widget faq */
.widget-faq .card
{
  margin-bottom: 5px;
  border: 0;
}
.widget-faq .card-header
{
  background-color: #faf8f5;
}

.widget-faq .card .card-header
{
  padding:0;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#50505c;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  font-size: 14px;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067";
}

.widget__bloglist
{
  padding: 20px;
}
@media(min-width: 1200px)
{
  .widget__bloglist
  {
    min-height: 430px;
  }
}
.widget__bloglist a:hover
{
  text-decoration: none!important;
}
.widget__bloglist h5
{
  font-family: 'Hind', sans-serif;
  font-size: 19px;
  font-weight: 300;
  line-height: 24px;
}
/* contact*/
.widget-contact h4
{
  font-size: 18px;
  font-weight: 600;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}
  .widget-contact li > a
, .widget-contact li > .jivo-btn
{
  display: block;
  position: relative;
  padding-left: 27px;
  color: #337ab7;
  cursor: pointer;
}
  .widget-contact li > a:hover
, .widget-contact li > .jivo-btn:hover
{
  text-decoration: underline;
}
.widget-contact .fa
{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 19px;
  color: #4dadd8;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* group for quicklink widgets */
.widget-widgetgroup
{
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  margin: 0 0 0 -30px;
  padding: 0;
}
.widget-widgetgroup > li
{
  flex: 1 1 50%;
  padding: 0 0 20px 30px;
}
.widget-widgetgroup > li > .widget
{
  height: 100%;
  margin-bottom: 0;
}
@media( max-width: 600px )
{
  .widget-widgetgroup
  {
    display: block;
    margin-left: 0;
  }
  .widget-widgetgroup > li
  {
    flex: none;
    width: 100%;
    padding-left: 0;
  }
}


.widget-quicklinks
{
  box-shadow: 0 0 7px rgba(0,0,0,0.2);
  color: #337ab7;
}
.widget-quicklinks__title
{
  font-size: 16px;
  padding: 8px 20px;
  position: relative;
  border-bottom: 1px solid #c6c1be;
  display: block;
}
.widget-quicklinks__title.withicon
{
  padding-left: 45px;
}

.widget-quicklinks .fa
{
  color: #4dadd8;
  position: absolute;
  top: 9px;
  left: 20px;
  display: inline-block;
}
  .widget-quicklinks__title
, .widget-quicklinks a
{
  color: inherit;
}
.widget-quicklinks a:hover
{
  color: #584a45;
  text-decoration: underline;
}
.widget-quicklinks ul
{
  list-style-type: none;
  margin: 0;
  padding: 10px 20px;
}
.widget-quicklinks ul > li
{
  display: block;
  position: relative;
  padding-left: 15px;
}
.widget-quicklinks ul > li:before
{
  font-family: FontAwesome;
  display: inline-block;
  color: #4dadd8;
  position: absolute;
  top: 0;
  left: 0;
  content: "\f105";
  font-size: 16px;
}

.widget-map
{
  padding-top: 75%;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  cursor: pointer;
}
.widget-map:after
{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-twocol__row__col .widget-map
{
  padding-top: 100%;
}
.widget-map__gmap
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-map__gmap .wh-infowindow
{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-top: -40px;
  margin-left: -20px;
}
.widget-map__gmap .wh-infowindow > *
{
  display: block;
}
.widget-map__gmap .wh-infowindow .title
{
  padding-top: 5px;
  font-size: 16px;
  line-height: 16px;

  width: 300px;
  margin-left: -130px;
}
.widget-login .wrdauth__loginbutton
{
  display: block;
}

  .twocol .row:after
, .twocol .col-md-6:after
{
  content: "";
  clear: both;
  display: block;
}

.twocol .col-md-6
{
  width: 50%;
  float: left;
}

@media(max-width: 768px)
{
  .twocol .col-md-6
  {
    float: none;
    width: 100%;
  }
}


.categorylist__list,
.subcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.subcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem
{
  font-size: 15px;
}
.categorylist__listitem
{
  list-style-type: none;
  padding: 1px 0;
}
.subcategorylist__listitem
{
  list-style-type: none;
  padding: 2px 0px 0 10px;
}
li.selected > .subcategorylist__list
{
  display: block;
}
li.selected > .subcategorylist__listitemlink {
  font-weight: 400;
  text-decoration: underline;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink
{
  color: #58585a;
}

.tagitems
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.tagitems li
{
  font-size: 15px;
  list-style-type: none;
  padding: 1px 0;
}
.tagitems a
{
  color: #58585a;
}

/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html.ecoyogi--verlanglijstje .site__content
{
  margin-top: 1.5rem;
}
html:not(.ecoyogi--verlanglijstje) .ecoyogi--only-verlanglijstje
{
  display:none;
}
.ecoyogi__removefromlist label
{
  cursor:pointer;
}
.ecoyogi__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.ecoyogi__removefromlist__text
{
  margin-left:5px;
}

.top-indicator {
    right: 0;
    top: 1rem;
    bottom: inherit;
    left: inherit;
    margin-right: 1rem;
}
.overflow {
    position: relative;
    overflow: hidden;
}
.zoom img {
    transition: all 0.2s linear;
}
.zoom:hover img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}


html:not(.webshop--iscategorypage) .ordering-container,
html.webshop--ishomepage .ordering-container
{
  display: none;
}
