::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* Chrome <=56, Safari < 10 */
:-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* FF 4-18 */
::-moz-placeholder {
  opacity: 1;
  -moz-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* FF 19-51 */
:-ms-input-placeholder {
  opacity: 1;
  -ms-transition: opacity 0.5s;
  transition: opacity 0.5s;
} /* IE 10+ */
::placeholder {
  opacity: 1;
  transition: opacity 0.5s;
} /* Modern Browsers */
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

html.dompack--busymodal body:before {
  content: "";
  display: block;
  position: fixed;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal body:after {
  content: "\f1ce";
  display: block;
  position: fixed;
  font-family: "FontAwesome";
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  z-index: 1000;
  opacity: 0.8;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.wh-form__label {
  min-width: 150px;
}
.wh-form input[type=radio],
.wh-form input[type=checkbox] {
  position: relative;
  top: 1px;
}
.wh-form__optiondata--vertical .wh-form__optionlabel {
  margin-left: 10px;
}
.wh-form__optiondata--vertical .wh-form__subfield {
  margin-left: 10px;
}
.wh-form__fieldgroup--required > .wh-form__label:after {
  content: "*";
}
.wh-form__errors {
  list-style-type: none;
  font-style: italic;
  color: #ff0000;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.wh-form .wh-form__error {
  position: relative;
  padding-left: 5px;
  color: #ff0000;
  font-size: 12px;
}
.wh-form input[type=text],
.wh-form input[type=email],
.wh-form input[type=password],
.wh-form input[type=date],
.wh-form input[type=number],
.wh-form input[type=url],
.wh-form input[type=tel],
.wh-form input[type=time],
.wh-form select,
.wh-form textarea,
.wh-form .webshop-listcart__inputamount {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  font: inherit;
  font-size: 16px;
  padding: 5px 10px;
  max-width: 300px;
  width: 100%;
  display: block;
}
.wh-form input[type=text]:not([disabled]),
.wh-form input[type=email]:not([disabled]),
.wh-form input[type=password]:not([disabled]),
.wh-form input[type=date]:not([disabled]),
.wh-form input[type=number]:not([disabled]),
.wh-form input[type=url]:not([disabled]),
.wh-form select:not([disabled]),
.wh-form textarea:not([disabled]),
.wh-form .webshop-listcart__inputamount:not([disabled]) {
  background-color: #fff;
}
.wh-form textarea {
  height: 143px;
}
.wh-form .wh-wrdauth__loginform {
  max-width: 300px;
}
.wh-form__fieldgroup {
  margin-bottom: 10px;
}
.wh-form__fieldgroup--textedit, .wh-form__fieldgroup--pulldown, .wh-form__fieldgroup--textarea, .wh-form__fieldgroup--date {
  display: block !important;
}
.wh-form__fieldgroup--hidden {
  display: none !important;
}
.wh-form__fieldgroup .wh-form__label {
  display: block;
  margin-bottom: 5px;
}
.wh-form input[type=text].wh-form__field--error,
.wh-form input[type=email].wh-form__field--error,
.wh-form input[type=url].wh-form__field--error,
.wh-form input[type=password].wh-form__field--error,
.wh-form input[type=tel].wh-form__field--error,
.wh-form input[type=number].wh-form__field--error,
.wh-form input[type=date].wh-form__field--error,
.wh-form select.wh-form__field--error {
  border-color: #ff0000;
}
.wh-form .wh-form__buttongroup .wh-form__button {
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  outline: 0;
  cursor: pointer;
  background-color: #8fc13e;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  border-color: #8fc13e;
}
.wh-form .wh-form__buttongroup .wh-form__button:hover {
  background-color: #a2db46;
}
.wh-form .wh-form__buttongroup .wh-form__button:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

html.page--webform .wh-form {
  /* file upload */
  /* image edit component */
}
html.page--webform .wh-form .wh-form__label:empty {
  display: none;
}
html.page--webform .wh-form .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  content: "*";
}
html.page--webform .wh-form .wh-form__fieldgroup--textedit,
html.page--webform .wh-form .wh-form__fieldgroup--pulldown,
html.page--webform .wh-form .wh-form__fieldgroup--textarea {
  display: flex !important;
}
html.page--webform .wh-form .wh-form__label {
  font-weight: bold;
  min-width: 200px;
  max-width: 200px;
  padding-right: 10px;
}
html.page--webform .wh-form .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
html.page--webform .wh-form .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
html.page--webform .wh-form .wh-form__optionlabel {
  margin-right: 10px;
}
html.page--webform .wh-form .wh-form__fieldgroup--horizontal .wh-form__fields .wh-form__fieldline {
  padding-right: 10px;
}
html.page--webform .wh-form .wh-form__fieldgroup--horizontal .wh-form__fields .wh-form__optionlabel {
  margin-left: 10px;
}
html.page--webform .wh-form .wh-form__fieldgroup--horizontal .wh-form__fields .wh-form__optiondata {
  width: 100%;
}
html.page--webform .wh-form .wh-form__pulldown + .wh-form__subfield {
  margin-left: 10px;
}
html.page--webform .wh-form .wh-form__subfield input {
  min-width: 200px;
}
html.page--webform .wh-form .wh-form__subfieldlabel {
  padding-right: 10px;
}
html.page--webform .wh-form .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
html.page--webform .wh-form input[type=text],
html.page--webform .wh-form input[type=email],
html.page--webform .wh-form input[type=password],
html.page--webform .wh-form input[type=date],
html.page--webform .wh-form input[type=number],
html.page--webform .wh-form input[type=url],
html.page--webform .wh-form input[type=tel],
html.page--webform .wh-form input[type=time],
html.page--webform .wh-form select,
html.page--webform .wh-form textarea,
html.page--webform .wh-form .webshop-listcart__inputamount {
  flex: 1;
  max-width: 100%;
}
html.page--webform .wh-form .wh-form__uploadbuttonlabel {
  display: none;
}
html.page--webform .wh-form .wh-form__uploadfieldselect.wh-form__button {
  width: 40px;
  height: 30px;
  overflow: hidden;
  padding: 12px 12px 12px 5px;
  background-color: transparent;
  border: 0 none;
  color: #ccc;
  white-space: normal;
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
}
html.page--webform .wh-form .wh-form__uploadfieldselect.wh-form__button::before {
  display: block;
  position: relative;
  top: -5px;
  font-family: "FontAwesome";
  content: "\f0ee";
  height: 30px;
  font-size: 24px;
}
html.page--webform .wh-form .wh-form__uploadfield--hasfile .wh-form__uploadfieldselect {
  display: none;
}
html.page--webform .wh-form .wh-form__uploadfielddelete {
  display: none;
  position: absolute;
  top: 3px;
  right: 2px;
  height: 26px;
  width: 30px;
  background-color: #fff;
  cursor: pointer;
}
html.page--webform .wh-form .wh-form__uploadfielddelete::after {
  font-family: "FontAwesome";
  content: "\f057";
  position: relative;
  top: -5px;
  display: inline-block;
  font-size: 22px;
  padding: 8px 0 0 5px;
  color: inherit;
}
html.page--webform .wh-form .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: inline-block;
}
html.page--webform .wh-form .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
html.page--webform .wh-form .wh-form__uploadfield .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
html.page--webform .wh-form .wh-form__uploadfield .wh-form__uploadfieldinputholder input {
  width: 100%;
}
html.page--webform .wh-form .wh-form__uploadfield .wh-form__uploadfieldfilename[type=text][disabled] {
  max-width: 100%;
  border-radius: 3px;
  border-color: #ccc;
  color: #aaa;
}
html.page--webform .wh-form .wh-form__uploadfield button {
  white-space: nowrap;
  min-height: 33px;
  padding: 0 10px;
  justify-content: center;
  margin-top: 0;
}
html.page--webform .wh-form .wh-form__uploadfield button::after {
  display: none;
}
html.page--webform .wh-form .wh-form__fieldgroup--error .wh-form__uploadfieldfilename[type=text][disabled] {
  border-color: #ff0000;
}
html.page--webform .wh-form .wh-form__fieldgroup--upload.wh-form--uploading > .wh-form__label::before {
  height: 0;
}
html.page--webform .wh-form .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(200, 200, 200, 0.2) 16.67%, transparent 16.67%, transparent 50%, rgba(200, 200, 200, 0.2) 50%, rgba(200, 200, 200, 0.2) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
html.page--webform .wh-form .wh-form__fieldgroup--imgedit {
  align-items: flex-start;
}
html.page--webform .wh-form .wh-form__fieldgroup--imgedit .wh-form__label::before {
  background-color: #eee;
  height: 2px;
}
html.page--webform .wh-form .wh-form__fieldgroup--imgedit.wh-form--uploading .wh-form__imgeditholder::before {
  z-index: 1;
  content: "";
  display: block;
  border: 0 none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
}
html.page--webform .wh-form .wh-form__fieldgroup--imgedit.wh-form--uploading .wh-form__imgeditholder::after {
  z-index: 1;
  content: "\f1ce";
  display: inline-block;
  flex: none;
  border: 0 none;
  position: absolute;
  width: 100px;
  height: 100px;
  font-size: 80px;
  line-height: 100px;
  font-family: "FontAwesome";
  color: #fff;
  top: 50%;
  margin-top: -40px;
  margin-bottom: 0;
  opacity: 0.8;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
html.page--webform .wh-form .wh-form__imgedit {
  width: 100%;
  max-width: 100%;
  background-color: #eee;
  border: 1px solid #ccc;
  outline: none;
  transition: border-width 0.2s;
}
html.page--webform .wh-form .wh-form__imgedit:focus, html.page--webform .wh-form .wh-form__imgedit:hover {
  border: 2px solid #ccc;
}
html.page--webform .wh-form .wh-form__imgedit::before {
  content: "";
  padding-top: 75%; /* 4/3 aspect */
  display: block;
}
html.page--webform .wh-form .wh-form__imgeditdelete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 23px;
  height: 23px;
  font-family: "FontAwesome";
  text-align: center;
  color: inherit;
  background-color: #fff;
  font-size: 24px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1;
  transition: opacity 0.5s;
  cursor: pointer;
  border-radius: 50%;
}
html.page--webform .wh-form .wh-form__imgeditdelete::before {
  position: relative;
  top: 2px;
  left: 0px;
  content: "\f057"; /* trash: \f1f8 */
}
html.page--webform .wh-form .wh-form__imgedit:hover .wh-form__imgeditdelete {
  opacity: 1;
}
html.page--webform .wh-form .wh-form__imgeditholder {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 20px;
  color: #aaa;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
html.page--webform .wh-form .wh-form__imgeditholder::before, html.page--webform .wh-form .wh-form__imgeditholder::after {
  display: block;
  width: 100%;
  flex: none;
  text-align: center;
  padding: 0 10px;
}
html.page--webform .wh-form .wh-form__imgeditholder::before {
  content: "\f0ee";
  font-family: "FontAwesome";
  width: 90px;
  height: 55px;
  font-size: 55px;
  line-height: 55px;
  margin-bottom: 10px;
}
html.page--webform .wh-form .wh-form__imgeditholder::after {
  content: "Drop of klik voor uploaden afbeelding";
}
html[lang=en] html.page--webform .wh-form .wh-form__imgeditholder::after {
  content: "Drop or click to upload image";
}
html.page--webform .wh-form .wh-form__imgedit--hasimage .wh-form__imgeditholder::before, html.page--webform .wh-form .wh-form__imgedit--hasimage .wh-form__imgeditholder::after {
  display: none;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
@media (max-width: 767px) {
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .wh-form__fields {
    width: 100%;
    flex-basis: auto;
  }
}
.wh-wrdauth-login__forgotpasswordlinkholder {
  padding-bottom: 10px;
}