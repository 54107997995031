@import url("https://fonts.googleapis.com/css?family=Hind:300,500,700&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=fre5sus&ht=tk&f=39262&a=536001&app=typekit&e=css");
@font-face {
  font-family: "ff-scala-sans-pro";
  src: url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/0f7856/00000000000000003b9b1f5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
.tk-ff-scala-sans-pro {
  font-family: "ff-scala-sans-pro", sans-serif;
}

footer {
  background: #fff;
}
footer #ondermenu {
  margin-bottom: 20px;
  padding: 50px 0 0 0;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
}
footer #footer__widget__1 img {
  max-width: 150px;
  width: 100%;
  height: auto;
}
footer .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer .footer__widget ul li {
  display: table-row;
}
footer .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #acd35f;
}
footer .footer__widget ul li a {
  color: #50505c;
}
footer .footer__widget .form-control {
  border-radius: 0;
  background-color: #f6f4f2;
  border: none;
}
footer .footer__widget .widget--kiyohwidget {
  width: 270px;
}
footer .bottom__logos {
  padding-bottom: 0px;
  text-align: center;
  padding-top: 25px;
  border-top: 1px solid #e1e1e1;
}
footer .bottom__logos img {
  max-height: 50px;
  width: auto;
  max-width: 55px;
  margin-right: 15px;
}
footer .bottom__logos .payment__logos {
  display: inline;
}
footer .bottom__logos .payment__logos img {
  max-height: 30px;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li a {
  color: #58595b;
}
footer .bottomnav ul li + li:before {
  content: "|";
  padding: 0 6px;
  display: inline-block;
}
footer h5 {
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
  color: #375b7b;
}
@media (max-width: 565px) {
  footer .bottom__logos {
    text-align: left;
  }
  footer .bottom__logos img {
    max-width: 43px;
    margin-right: 15px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}